<template>
    <div>
        <div class="pt-3 pb-3 text-center">
            <a href="https://bucinhoki.net" target="_blank">
                <img src="@/assets/Bucin-Hoki-Front.jpeg" style="width:auto;height:80%"/>
            </a>            
        </div>
        <div class="text-center">
            <div class="row">
                <div class="col-lg-2 col-0">

                </div>
                <div class="col-lg-8 col-12">
                    <div class="row">
                        <div class="col-lg-3 col-3"  v-for="selectProvider in Object.keys(rtp_provider)" :key="selectProvider">
                            <button
                                class="btn"
                                :class="{'active' : selectProvider == provider}"
                                @click="provider = selectProvider"
                            >
                                <img :src="requireImage(selectProvider)" alt="logo" style="width:50px;height: auto;" />
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <Roller value="1234"></Roller>
        

        <!-- {{ rtp_data }} -->
        <div style="padding-left: 3%;padding-right: 3%;">
            <div class="row">
                <div class="col-lg-2 col-6 mt-3 mb-3 pl-2 pr-2" v-for="data in rtp_provider[provider].slice(0 , isShow)" :key="data.id">
                    <a href="https://bucinhoki.net" target="_blank">
                        <div class="p-15">
                            <img :src="data.img_link" class="img-rtp-image"/>
                        </div>
                        <div class="text-center">
                            <h5 class="truncate">{{ data.name }}</h5>
                        </div>
                        <div class="progress bg-secondary position-relative">
                            <div :class="{'bg-success' : data.value > 66, 'bg-warning' : data.value < 66 && data.value > 33 , 'bg-danger' : data.value < 33}" 
                                class="progress-bar" 
                                role="progressbar" 
                                :style="{width: data.value + '%'}" 
                                :aria-valuenow="data.value" 
                                aria-valuemin="0" 
                                aria-valuemax="100">
                            </div>
                            <div class="value-label position-absolute w-100 text-center">{{ data.value }} %</div>
                        </div>
                    </a>
                    <!-- {{ data }} -->
                    
                </div>

            </div>
            <div class="text-center mt-3 mb-3">
                <button class="btn btn-secondary" @click="isShow += 48">Load More</button>
            </div>
            
        </div>
        

    </div>
</template>

<script>

import { Roller } from "vue-roller";


export default {
    components : {
        Roller
    },
    name : 'AppBody',
    data(){
        return {
            rtp_data_full : [],
            provider : 'Pragmatic Play',
            rtp_provider : {
                'Pragmatic Play' : [],
                PG : [],
                AP : [],
                
                Habanero : [],
                // 'Red Tiger' : [],
                MicroGaming : [],
                // Netent : [],
                
                // XIN : [],
                // 'Spade Gaming' : [],
                CQ9 : [],
                // YGG : [],
                // NolimitCity : [],
                Joker : [],
                AIS : [],
            },
            isShow : 30,
            loading: false

        }
    },
    created () {
        this.loadRtpData()
    },
    mounted () {
        
    },
    watch : {
        provider : function () {
            this.isShow = 30;
        }
    },
    methods : {
        loadRtpData() {
            this.$http.get('https://api.smssekarangbangetdong.com/rtp-data')
            .then((resp) => {
                this.rtp_data_full = resp.data

                for(var i = 0 ; i < this.rtp_data_full.length ; i++) {
                    let curData = this.rtp_data_full[i]
                    curData.value = (curData.value / 100).toFixed(2)
                    if(!this.rtp_provider[curData.provider]) continue;
                    this.rtp_provider[curData.provider].push(curData)
                }
                
            })
            .catch((err) => {
                console.log(err)
            })
        },
        requireImage (provider) {
            return require('./../assets/' + provider + '.png')
        }
    }
}
</script>

<style scoped>
    .p-20 {
        padding : 20px;
    }

    h5 {
        color : white;
    }

    .value-label {
        top: 50%;
        transform: translateY(-50%);
        z-index: 2; /* ensures the label is on top */
        color : white;
        font-weight: bold;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .img-rtp-image {
        height: 150px;
        width: 100%;
    }

    /* Styles for laptop or larger screens */
    @media (min-width: 1024px) {
        .img-rtp-image {
            height: 200px;
        }
    }
</style>

