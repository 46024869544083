<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <AppHeader></AppHeader>
    
      <AppBody class="mt-4"></AppBody>

      <template>
        <LiveChatWidget
          license="17320278"
          visibility="minimized"
          v-on:new-event="handleNewEvent"
        />
      </template>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import AppHeader from './components/Header.vue'
import AppBody from './components/Body.vue'
import { LiveChatWidget } from '@livechat/widget-vue/v2'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppBody,
    LiveChatWidget
  },
  methods : {
    handleNewEvent(event) {
      console.log('LiveChatWidget.onNewEvent', event)
    },
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color :#333;
  height:100vh;
  overflow-y: scroll;
}
</style>

