<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-geger">
        <div class="container-fluid">
            <!-- Logo -->
            <a class="navbar-brand" href="#" target="_blank">
                <img src="@/assets/BucinHoki.png" alt="Logo" width="180" height="50" class="d-inline-block align-top">
                <!-- Your Brand -->
            </a>
            
            <!-- Toggler/collapsible Button (for smaller screens) -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
            <!-- Navbar content on the right -->
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a class="nav-link btn btn-secondary" style="color:coral;font-weight: bold;" href="https://bucinhoki.net" target="_blank">Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn btn-secondary" style="color:tomato;font-weight: bold" href="https://bucinhoki.net" target="_blank">Register</a>
                </li>
            </ul>
            </div>
        </div>
    </nav>
  </template>
  
  <script>
  export default {
    name : 'AppHeader',
    methods: {
      login() {
        // Handle login logic here
        console.log('Login clicked');
      },
      register() {
        // Handle register logic here
        console.log('Register clicked');
      },
    },
  };
  </script>
  
  <style scoped>
  .bg-geger {
    background-color: #000;
  }
  
  .logo img {
    max-height: 40px;
  }
  
  .actions button {
    margin-left: 10px;
  }
  </style>